
// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {

	// COMPONENT
		// vx-autosuggest
	// starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {

    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return "xl"
    else if (state.windowWidth >= 992) return "lg"
    else if (state.windowWidth >= 768) return "md"
    else if (state.windowWidth >= 576) return "sm"
    else return "xs"
  },
  orderStatus: () => status => {
    if(!status) return;
    let code = { pending : '#1e1e1e', completed : '#1dbf73', refunded  : '#EA5455',
                    draft  : '#EA5455', delivered : '#FF9F43', revision: '#446ee7' }
    return `<span style="background:${code[status]}24; color:${code[status]}" class="px-2 py-1 rounded">${status}</span>`
  },
  deliveryDate:() => (startDate, hrs) => {
    if(!startDate || !hrs) return;
    let option = { year:'2-digit', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit'}
    var givenDate  = new Date().setTime(new Date(startDate).getTime() + (parseInt(hrs)*60*60*1000));
    return new Intl.DateTimeFormat('en-US', option).format(givenDate)
  },
}

export default getters
