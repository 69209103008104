import navbarSearchAndPinList from "@/components/app/top-navbar/navbarSearchAndPinList"
import themeConfig from "@/themeConfig.js"
import colors from "@/themeConfig.js"
import env from '../env.js'
import { getUserData } from '@/auth/utils'


// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser           : getUserData(),
    activeUserUnreadChats   : 0,
    bodyOverlay             : false,
    isVerticalNavMenuActive : true,
    mainLayoutType          : themeConfig.mainLayoutType || "vertical",
    navbarSearchAndPinList  : navbarSearchAndPinList,
    reduceButton            : themeConfig.sidebarCollapsed,
    verticalNavMenuWidth    : "default",
    verticalNavMenuItemsMin : false,
    scrollY                 : 0,
    starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
    theme                   : themeConfig.theme || "light",
    themePrimaryColor       : colors.primary,
    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
    languages               : [],
    skills                  : [],
    countries               : [],
    domain                  : env.domain,
    localUserKey            : env.localUserKey
}

export default state
