export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES - Main Routes
    // =============================================================================
        path: '',
        redirect: '/',
        component: () => import('@layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                    pageTitle: 'Dashboard',
                    authRequired: true,
                }
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@views/account/UserEdit.vue'),
                meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/' },
                        { title: 'Account', active: true },
                    ],
                    pageTitle: 'Profile',
                    authRequired: true,
                },
            },
            {
                path: '/orders',
                name: 'orders',
                component: () => import('@views/orders/OrderList.vue'),
                meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/' },
                        { title: 'Orders', active: true },
                    ],
                    pageTitle: 'Orders',
                    authRequired: true,
                },
            },
            {
                path: '/orders/:orderId(\\d+)',
                name: 'order-view',
                component: () => import('@views/orders/OrderShow.vue'),
                meta: {
                  breadcrumb: [
                      { title: 'Home', url: '/' },
                      { title: 'Pending', url: '/orders?status=pending' },
                      { title: 'Delivered', url: '/orders?status=delivered' },
                      { title: 'Revision', url: '/orders?status=revision' },
                      { title: 'Completed', url: '/orders?status=completed' },
                      { title: 'Order View', active: true },
                  ],
                  pageTitle: 'Orders',
                  authRequired: true,
                },
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: () => import('@views/notification/Notifications.vue'),
                meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/' },
                        { title: 'Notification List', active: true },
                    ],
                    pageTitle: 'Notifications',
                    authRequired: true,
                }
            },
            {
                path: '/chat',
                name: 'chat',
                component: () => import('@views/chat/Chat.vue'),
                meta: {
                    authRequired: true,
                    pageTitle: 'Chat',
                }
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '',
        component: () => import('@layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@views/pages/Login.vue'),
                meta: {
                    pageTitle: 'Login',
                    guestRequired: true
                }
            },
            {
              path: '/register',
              name: 'register',
              component: () => import('@/views/pages/Register.vue'),
              meta: {
                pageTitle: 'Register',
                guestRequired: true,
              }
            },
            {
                path: '/error-404',
                name: 'error-404',
                component: () => import('@views/pages/Error404.vue'),
                meta: {
                    pageTitle: '404 Error',
                    guestRequired: true
                }
            },
            {
                path: '/error-500',
                name: 'error-500',
                component: () => import('@views/pages/Error500.vue'),
                meta: {
                    pageTitle: '500 Error',
                    guestRequired: true
                }
            }
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '*',
        redirect: '/error-404'
    },

]