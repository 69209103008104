import Vue from 'vue'
import Router from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import routes from './routes'
import env from '../env.js'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes
});


router.afterEach((to, from, next) => {
  // Remove initial loading
  const appLoading = document.getElementById('loader-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
  document.title = to.meta.pageTitle+' | '+env.appName
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if(to.meta.authRequired) {
    if (!isLoggedIn) return next({path: '/login' });
  }
  if(to.meta.guestRequired) {
    if (isLoggedIn) return next({path: '/' });
  }
  return next();
});

export default router

