
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleNotification from "./modules/notification.js"
import moduleOrderList from './modules/order.js'
import moduleChat      from './modules/chat.js'

export default new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    notification: moduleNotification,
    orderList: moduleOrderList,
    chat: moduleChat
  },
  strict: process.env.NODE_ENV !== 'production'
})
